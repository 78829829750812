<div class="content">
    <div class="widget">
        <h2>Restablecer contraseña</h2>
        <p>Escriba la dirección de correo electrónico asociada a su cuenta y recibirá un correo electrónico para
            restablecer su contraseña.</p>
        <form (ngSubmit)="onSubmit()" #forgotPasswordForm="ngForm">
            <input type="text" [(ngModel)]="email" name="email" required placeholder="Email" />
            <div class="footer">
                <button class="btn btn-primary mr-3" type="submit"
                    [disabled]="!forgotPasswordForm.form.valid">Enviar</button>
                <button class="btn btn-secondary" type="button" (click)="cancelar()">Cancelar</button>
            </div>
        </form>
    </div>
</div>