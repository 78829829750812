import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private modalService: BsModalService) {}

  handleComponentError(error: any) {
    this.getJsonError(error).then((jsonError) =>
      this.modalService.show(AlertModalComponent, {
        class: 'modal-sm',
        initialState: {
          title: `${jsonError.message} (error ${jsonError.code})`,
        },
      })
    );
  }

  handleModalError(error: any, modalRef: BsModalRef) {
    modalRef.hide();
    this.getJsonError(error).then((jsonError) =>
      this.modalService.show(AlertModalComponent, {
        class: 'modal-sm',
        initialState: {
          title: `${jsonError.message} (error ${jsonError.code})`,
        },
      })
    );
  }

  private getJsonError(error: any): Promise<any> {
    if (error.error instanceof Blob && error.error.type === 'application/json') {
      // https://github.com/angular/angular/issues/19888
      return this.getBlobError(error.error);
    } else {
      return new Promise<any>((resolve) => resolve(error.error));
    }
  }

  private getBlobError(error: Blob): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        try {
          resolve(JSON.parse((e.target as any).result));
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsText(error);
    });
  }

  handlePromiseError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error);
  }
}
