import { Component, OnInit, Input } from '@angular/core';
import { AuthService, Usuario } from 'emc2-lib';
import { AppConfig } from '../app.config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SettingsComponent } from '../settings/settings.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() user: Usuario;

  constructor(public auth: AuthService, public config: AppConfig, private modalService: BsModalService) {}

  ngOnInit(): void {}

  openSettings() {
    this.modalService.show(SettingsComponent);
  }
}
