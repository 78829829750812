<div class="content">
    <div class="widget">
        <h2>Restablecer contraseña</h2>
        <form (ngSubmit)="onSubmit()" #resetPasswordForm="ngForm">
            <table>
                <tr>
                    <td><label>Contraseña: </label></td>
                    <td>
                        <div class="app-password-strength-bar-wrapper">
                            <input #password="ngModel" type="password" name="contrasena" [(ngModel)]="contrasena"
                                placeholder="Contraseña" required minlength="6" />
                            <app-password-strength-bar [passwordToCheck]="contrasena"></app-password-strength-bar>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><label>Repetir: </label></td>
                    <td><input type="password" name="repetirContrasena" [(ngModel)]="repetirContrasena"
                            placeholder="Repetir contraseña" [validateEqual]="contrasena" /></td>
                </tr>
            </table>
            <p class="error" [hidden]="password.valid || (!contrasena && !repetirContrasena)">
                La contraseña debe tener como mínimo 6 caracteres.
            </p>
            <p class="error" [hidden]="!password.valid || contrasena == repetirContrasena">
                Las contraseñas no coinciden.
            </p>
            <div class="footer">
                <button class="btn btn-secondary mr-3" type="button" (click)="onCancel()">Cancelar</button>
                <button class="btn btn-primary" type="submit"
                    [disabled]="!resetPasswordForm.form.valid">Restablecer</button>
            </div>
        </form>
    </div>
</div>