import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'emc2-lib';
import { ErrorHandlingService } from '../core/error-handling.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  email: string;
  errorMessage: string;

  constructor(
    private usuarioService: UsuarioService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  onSubmit(): void {
    this.usuarioService
      .forgotPassword(this.email)
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch((error) => this.errorHandlingService.handleComponentError(error));
  }

  cancelar(): void {
    this.router.navigate(['/login']);
  }
}
