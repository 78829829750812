<div class="modal-header">
  <h4 class="modal-title pull-left">Ajustes</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label class="checkbox-container mt-2">
    Corrector ortográfico habilitado.
    <input type="checkbox" [checked]="spellcheckEnabled" #spellcheck class="mr-1" />
    <span class="checkmark"></span>
  </label>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary mr-1 flex-fill" (click)="saveSettings(spellcheck.checked)">
    Guardar
  </button>
  <button type="button" class="btn btn-outline-primary flex-fill" (click)="hide()">Cancelar</button>
</div>
