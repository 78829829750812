import { Component, OnChanges, Input, SimpleChange } from '@angular/core';

declare var zxcvbn: any;

@Component({
  selector: 'app-password-strength-bar',
  styles: [
    `
      ul#strengthBar {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        vertical-align: 2px;
      }
      .point:last {
        margin: 0 !important;
      }
      .point {
        background: #ddd;
        border-radius: 2px;
        display: inline-block;
        height: 5px;
        margin-right: 1px;
        flex-grow: 1;
      }
    `,
  ],
  template: `
    <ul id="strengthBar">
      <li *ngFor="let bar of bars" class="point" [style.background-color]="bar"></li>
    </ul>
  `,
})
export class PasswordStrengthBarComponent implements OnChanges {
  @Input() passwordToCheck: string;
  bars: string[] = Array(5).fill('#EEE');

  private colors = ['#FF4136', '#FF851B', '#FFDC00', '#01FF70', '#2ECC40'];

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.setBarColors(5, '#EEE');
    if (password) {
      const results = zxcvbn(password);
      const index = results.score;
      const color = this.colors[index];
      this.setBarColors(index + 1, color);
    }
  }
  private setBarColors(count, col) {
    for (let i = 0; i < count; i++) {
      this.bars[i] = col;
    }
  }
}
