import { Component } from '@angular/core';
import { LibConfig } from 'emc2-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'EMC2';

  constructor(libConfig: LibConfig) {
    // libConfig.baseUrl = 'http://localhost:8084/EMC2GeneralWS';
    libConfig.baseUrl = 'https://api.emc2apps.com/EMC2GeneralWS';
  }
}
