import { Component, OnInit } from '@angular/core';
import { AuthService, Usuario, UsuarioService } from 'emc2-lib';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  user: Usuario;

  constructor(public auth: AuthService, private userService: UsuarioService) {}

  ngOnInit(): void {
    this.userService.getUsuario(this.auth.getUserId()).then((user) => (this.user = user));
  }
}
