<div class="content">
    <div *ngIf="usuario">
        <div class="widget">
            <h2>Activar cuenta</h2>
            <form (ngSubmit)="onSubmit()" #activateForm="ngForm">
                <table>
                    <tr>
                        <td><label>Id: </label></td>
                        <td>{{usuario.id}}</td>
                    </tr>
                    <tr>
                        <td><label>Email: </label></td>
                        <td>{{usuario.email}}</td>
                    </tr>
                    <tr>
                        <td><label>Nombre: </label></td>
                        <td><input name="nombre" [(ngModel)]="usuario.nombre" placeholder="Nombre" /></td>
                    </tr>
                    <tr>
                        <td><label>Apellidos: </label></td>
                        <td><input name="apellidos" [(ngModel)]="usuario.apellidos" placeholder="apellidos" /></td>
                    </tr>
                    <tr>
                        <td><label>Contraseña: </label></td>
                        <td>
                            <div class="app-password-strength-bar-wrapper">
                                <input #password="ngModel" type="password" name="contrasena" [(ngModel)]="contrasena"
                                    placeholder="Contraseña" required minlength="6" />
                                <app-password-strength-bar [passwordToCheck]="contrasena"></app-password-strength-bar>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td><label>Repetir: </label></td>
                        <td><input type="password" name="repetirContrasena" [(ngModel)]="repetirContrasena"
                                placeholder="Repetir contraseña" [validateEqual]="contrasena" /></td>
                    </tr>
                </table>
                <p *ngIf="password.invalid && (password.dirty || password.touched) && password.errors.minlength"
                    class="error">
                    La contraseña debe tener como mínimo 6 caracteres.
                </p>
                <p *ngIf="password.valid && contrasena != repetirContrasena" class="error">
                    Las contraseñas no coinciden.
                </p>
                <div class="footer">
                    <button class="btn btn-secondary mr-3" type="button" (click)="onCancel()">Cancelar</button>
                    <button class="btn btn-primary" type="submit" [disabled]="!activateForm.form.valid">Activar</button>
                </div>
            </form>
        </div>
    </div>
</div>