<div class="nav">
  <div class="logo mr-3">
    <a href="index.html"><img src="./assets/emc2.png" height="40" /></a>
  </div>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav sidebar-nav mr-auto">
      <li class="mr-3">
        <a routerLink="/aplicaciones" routerLinkActive="active">Aplicaciones</a>
      </li>
      <li>
        <a routerLink="/posicion-global" routerLinkActive="active">Posición global</a>
      </li>
    </ul>
    <li class="dropdown">
      <a
        class="dropdown-toggle"
        data-toggle="dropdown"
        role="button"
        href="javascript:void(0);"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ user.nombreCompleto }}
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" (click)="openSettings()" href="javascript:void(0);">Ajustes</a>
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item" (click)="auth.logout()" href="javascript:void(0);">Salir</a>
        <a class="dropdown-item text-secondary">v{{ config.version }}</a>
      </div>
    </li>
  </div>
</div>
