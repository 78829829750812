import { Injectable, Injector, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { bugsnagClient } from '../bugsnag-client';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  router: Router;

  constructor(private injector: Injector, private zone: NgZone) {
    // Calling this.injector.get(Router) here resulted in a null Router
  }

  handleError(error) {
    // https://stackoverflow.com/questions/48787669/angular-navigation-in-errorhandler-only-works-with-zone-run
    // https://github.com/angular/angular/issues/20290
    this.zone.run(() => {
      console.log(error);
      bugsnagClient.notify(error);
      // Calling the injector here gave the application the time to build the correct context for the dependency injection.
      if (this.router == null) {
        this.router = this.injector.get(Router);
      }
      const errorStatus = error.rejection.status || error.status;
      setTimeout(() => {
        if (errorStatus) {
          this.router.navigate(['/error'], {
            queryParams: { code: errorStatus },
          });
        } else {
          this.router.navigate(['/error']);
        }
      }, 0);
    });
  }
}
