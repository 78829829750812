import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ActivateComponent } from './activate/activate.component';
import { ErrorComponent } from './error/error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'aplicaciones', pathMatch: 'full' },
      {
        path: 'aplicaciones',
        loadChildren: () => import('./systems/systems.module').then((m) => m.SystemsModule),
      },
      {
        path: 'posicion-global',
        loadChildren: () => import('./global-position/global-position.module').then((m) => m.GlobalPositionModule),
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'activate/:id/:hash', component: ActivateComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:id/:hash', component: ResetPasswordComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
