import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Usuario, UsuarioService } from 'emc2-lib';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  hash: string;
  usuarioId: number;
  contrasena: string;
  repetirContrasena: string;
  @ViewChild('resetPasswordForm') resetPasswordForm: NgForm;

  constructor(private route: ActivatedRoute, private router: Router, private usuarioService: UsuarioService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.hash = params.hash;
      this.usuarioId = +params.id;
    });
  }

  onSubmit(): void {
    const usuario = new Usuario();
    usuario.id = this.usuarioId;
    usuario.contraseña = this.contrasena;
    this.usuarioService.resetPassword(usuario, this.hash).then((_usuario) => this.router.navigateByUrl('/login'));
  }

  onCancel(): void {
    this.router.navigateByUrl('/login');
  }
}
