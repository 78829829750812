import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Usuario, UsuarioService } from 'emc2-lib';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
})
export class ActivateComponent implements OnInit {
  hash: string;
  contrasena: string;
  repetirContrasena: string;
  usuario: Usuario;
  @ViewChild('activateForm') activateForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.hash = params.hash;
          return this.usuarioService.getUsuarioForActivation(+params.id, this.hash).catch((_error) => null);
        })
      )
      .subscribe((usuario) => {
        if (!usuario) {
          this.router.navigateByUrl('/login');
        }
        this.usuario = usuario;
      });
  }

  onSubmit(): void {
    this.usuario.contraseña = this.contrasena;
    this.usuarioService.activate(this.usuario, this.hash).then((usuario) =>
      this.router.navigate(['/login'], {
        queryParams: { email: usuario.email },
      })
    );
  }

  onCancel(): void {
    this.router.navigateByUrl('/login');
  }
}
