<div class="content">
    <div [ngSwitch]="code" class="widget">
        <ng-template [ngSwitchCase]="401">
            <h3>No autorizado</h3>
            <p>Se niega el acceso porque las credenciales no son válidas.</p>
        </ng-template>
        <ng-template ngSwitchDefault>
            <h3>¡Ups! Se ha producido un error</h3>
        </ng-template>
    </div>
</div>