import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  title: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {}

  accept(): void {
    this.bsModalRef.hide();
  }
}
