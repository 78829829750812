<div class="content">
    <div class="widget">
        <img class="logo" src="./assets/emc2_color.png" width="120" />
        <hr />
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <input type="text" [(ngModel)]="model.email" (ngModelChange)="onChangeModel()" (focus)="onFocusEmail()"
                (blur)="onBlurEmail()" name="email" required placeholder="Email" /><br />
            <input type="password" [(ngModel)]="model.password" (ngModelChange)="onChangeModel()" name="password"
                required placeholder="Contraseña" /><br />
            <p class="error" *ngIf="errorMessage">{{errorMessage}}</p>
            <div class="footer">
                <button class="btn btn-primary" type="submit" [disabled]="!loginForm.form.valid">Entrar</button>
            </div>
        </form>
        <p><a routerLink="/forgot-password">¿Ha olvidado su contraseña?</a></p>
    </div>
</div>