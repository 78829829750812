<div *ngIf="user" class="content-wrap">
  <nav role="navigation" class="navbar navbar-expand-sm sidebar">
    <app-navbar class="w-100" [user]="user"></app-navbar>
  </nav>
  <div class="flex-grow-1">
    <main id="content" class="content" role="main">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
