import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'emc2-lib';
import { ErrorHandlingService } from '../core/error-handling.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  submittedModel: any = {};
  errors = new Map<string, string>();

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.model.email = params.email || null;
    });
  }

  onFocusEmail() {
    this.errors.delete('email');
  }

  onBlurEmail() {
    if (this.model.email && !this.isValidEmail()) {
      this.errors.set('email', 'El texto introducido no parece un correo electrónico.');
    }
  }

  onChangeModel() {
    if (this.errors.has('submit') && !this.isSubmittedModel()) {
      this.errors.delete('submit');
    }
    if (!this.errors.has('submit') && this.isSubmittedModel()) {
      this.setSubmitError();
    }
  }

  isSubmittedModel(): boolean {
    return this.model.email === this.submittedModel.email && this.model.password === this.submittedModel.password;
  }

  isValidEmail(): boolean {
    return /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.model.email);
  }

  get errorMessage(): string {
    return [this.errors.get('submit'), this.errors.get('email')].filter((e) => e).join(' ');
  }

  onSubmit(): void {
    Object.assign(this.submittedModel, this.model);
    this.auth
      .logIn(this.model)
      .then((usuario) => {
        // Send the user to the homepage after login
        this.router.navigate(['']);
      })
      .catch(this.handleError.bind(this));
  }

  setSubmitError(): void {
    this.errors.set('submit', 'El correo electrónico y la contraseña no coinciden.');
  }

  private handleError(error: any) {
    error.status === 401 ? this.setSubmitError() : this.errorHandlingService.handleComponentError(error);
  }
}
