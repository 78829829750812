import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  get spellcheckEnabled(): boolean {
    return localStorage.getItem('disable_spellcheck') !== 'true';
  }

  constructor(private modalRef: BsModalRef) {}

  saveSettings(spellcheckEnabled: boolean) {
    localStorage.setItem('disable_spellcheck', String(!spellcheckEnabled));
    this.hide();
  }

  hide() {
    this.modalRef.hide();
  }
}
